import offside from "offside-js";

document.addEventListener("DOMContentLoaded", () => {
	const menu = document.querySelector("#site-menu");
	menu.classList.remove("js-hidden");

	var primaryNavOffside = offside("#site-menu", {
		// Global offside options: affect all offside instances
		slidingElementsSelector: ".site-wrapper", // String: Sliding elements selectors ('#foo, #bar')
		disableCss3dTransforms: false, // Disable CSS 3d Transforms support (for testing purposes)
		debug: true, // Boolean: If true, print errors in console

		// Offside instance options: affect only this offside instance
		buttonsSelector: "#site-header-burger", // String: Offside toggle buttons selectors ('#foo, #bar')
		slidingSide: "right" // String: Offside element pushed on left or right
	});
	var overlay = document
		.querySelector(".site-offcanvas-overlay")
		.addEventListener("click", primaryNavOffside.close);
});

//Add collapsing sections
document.addEventListener("DOMContentLoaded", () => {
	(function() {
		const menuItems = document.querySelectorAll(
			"#site-menu li.menu-item-has-children"
		);

		Array.prototype.forEach.call(menuItems, menuItem => {
			if (menuItem.classList.contains("current-menu-item")) {
				menuItem.classList.add("is-open");
			} else {
				menuItem.classList.add("is-closed");
			}

			var menuItemLink = menuItem.firstChild;

			if (menuItemLink.tagName === "A") {
				var wrapper = document.createElement("div");
				wrapper.classList.add("menu-item-collapse");
				menuItemLink.parentNode.insertBefore(wrapper, menuItemLink);

				wrapper.appendChild(menuItemLink);

				var toggle = document.createElement("div");
				toggle.classList.add("menu-item-toggle");

				var toggleIcon = document.createElement("span");
				toggleIcon.classList.add("menu-item-toggle-icon");
				toggleIcon.innerHTML = "<i class='fa fa-caret-down'></i>";

				toggle.onclick = () => {
					menuItem.classList.toggle("is-open");
					menuItem.classList.toggle("is-closed");
				};

				toggle.appendChild(toggleIcon);
				wrapper.appendChild(toggle);
			}
		});
	})();
});
